<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  listSaleContrastAPI,
  listSaleContrastSummaryAPI,
} from "@/api/shop/report/saleRatio"; //单据查询
export default {
  name: "SaleRatio",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "门店销售对比",
        listNo: true, // 序号
        //搜索
        search: [
          {
            label: "销售时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/门店编号/门店名称/商品编号/商品名称/条码",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "goodsNos", label: "商品编号" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "barcodes", label: "条码" },
            ],
          },
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "category",
        tabsColumns: [
          {
            title: "按类别占比",
            type: "category",
            getListApi: listSaleContrastAPI,
            getSummaryApi: listSaleContrastSummaryAPI,
            uuid: "dd504d2b-af5c-4ec4-61af-b8c03dcafb16",
            defaultBody: { summaryType: 2 },
            exportOption: {
              show: true,
              fastExportUrl:
                "/api/system/shop/report/sale/contrast/listSaleContrastExport",
              exportName: "门店销售按类别对比",
            }, //表格
            columns: [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "payTypes",
                align: "center",
                label: "",
                getColumns: (list) => {
                  let colums = [];
                  if (list?.[0]?.shops?.length) {
                    colums = list[0].shops.map((x, i) => ({
                      label: x.shopName,
                      prop: String(x.shopId),
                      children: [
                        {
                          label: "数量",
                          prop: "unitQty",
                          minWidth: 100,
                          summary: true,
                        },
                        {
                          label: "销售数量占比%",
                          prop: "unitQtyRate",
                          minWidth: 135,
                        },
                        {
                          label: "金额",
                          prop: "unitMoney",
                          minWidth: 100,
                          summary: true,
                        },
                        {
                          label: "销售金额占比%",
                          prop: "unitMoneyRate",
                          minWidth: 135,
                        },
                      ].map((y) => ({
                        ...y,
                        prop: `goodsShops.${x.shopId}.shopId.${y.prop}`,
                        summary: true,
                        getValue: (row, props) => {
                          const [key, id, idKey, prop] = props.split(".");
                          return (
                            row[key]?.find?.((x) => String(x[idKey]) === id)?.[prop] ||
                            "0"
                          );
                        },
                      })),
                    }));
                  }
                  return colums;
                },
              },
            ],
            summary: ["unitQty", "unitMoney"],
          },
          {
            title: "按商品占比",
            type: "goods",
            getListApi: listSaleContrastAPI,
            getSummaryApi: listSaleContrastSummaryAPI,
            uuid: "f0842776-f59b-f9a1-3020-d2ba4e32336e",
            defaultBody: { summaryType: 1 },
            exportOption: {
              show: true,
              fastExportUrl:
                "/api/system/shop/report/sale/contrast/listSaleContrastExport",
              exportName: "门店销售按商品对比",
            }, //表格
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "payTypes",
                align: "center",
                label: "",
                getColumns: (list) => {
                  let colums = [];
                  if (list?.[0]?.shops?.length) {
                    colums = list[0].shops.map((x, i) => ({
                      label: x.shopName,
                      prop: String(x.shopId),
                      children: [
                        {
                          label: "数量",
                          prop: "unitQty",
                          minWidth: 100,
                          summary: true,
                        },
                        {
                          label: "销售数量占比%",
                          prop: "unitQtyRate",
                          minWidth: 135,
                        },
                        {
                          label: "金额",
                          prop: "unitMoney",
                          minWidth: 100,
                          summary: true,
                        },
                        {
                          label: "销售金额占比%",
                          prop: "unitMoneyRate",
                          minWidth: 135,
                        },
                      ].map((y) => ({
                        ...y,
                        prop: `goodsShops.${x.shopId}.shopId.${y.prop}`,
                        summary: true,
                        getValue: (row, props) => {
                          const [key, id, idKey, prop] = props.split(".");
                          return (
                            row[key]?.find?.((x) => String(x[idKey]) === id)?.[prop] ||
                            "0"
                          );
                        },
                      })),
                    }));
                  }
                  return colums;
                },
              },
            ],
            summary: ["unitQty", "unitMoney"],
          },
          {
            title: "按门店占比",
            type: "shop",
            getListApi: listSaleContrastAPI,
            getSummaryApi: listSaleContrastSummaryAPI,
            uuid: "79dcb1a1-d85d-7a19-2f69-4bf9e5ab6191",
            defaultBody: { summaryType: 3 },
            exportOption: {
              show: true,
              fastExportUrl:
                "/api/system/shop/report/sale/contrast/listSaleContrastExport",
              exportName: "门店销售按门店对比",
            }, //表格
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "payTypes",
                align: "center",
                label: "",
                getColumns: (list) => {
                  let colums = [];
                  if (list?.[0]?.shops?.length) {
                    colums = list[0].shops.map((x, i) => ({
                      label: x.shopName,
                      prop: String(x.shopId),
                      children: [
                        {
                          label: "数量",
                          prop: "unitQty",
                          minWidth: 100,
                          summary: true,
                        },
                        {
                          label: "销售数量占比%",
                          prop: "unitQtyRate",
                          minWidth: 135,
                        },
                        {
                          label: "金额",
                          prop: "unitMoney",
                          minWidth: 100,
                          summary: true,
                        },
                        {
                          label: "销售金额占比%",
                          prop: "unitMoneyRate",
                          minWidth: 135,
                        },
                      ].map((y) => ({
                        ...y,
                        prop: `goodsShops.${x.shopId}.shopId.${y.prop}`,
                        summary: true,
                        getValue: (row, props) => {
                          const [key, id, idKey, prop] = props.split(".");
                          return (
                            row[key]?.find?.((x) => String(x[idKey]) === id)?.[prop] ||
                            "0"
                          );
                        },
                      })),
                    }));
                  }
                  return colums;
                },
              },
            ],
            summary: ["unitQty", "unitMoney"],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        default:
          break;
      }
    },
  },
};
</script>
